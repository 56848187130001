:root {
    --primary: #1e7c88;
}
iframe {
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 100%;
    width: 100%;
    /* z-index: 20000; */
}

textarea {
    width: 100% !important;
    outline-color: rgb(235, 235, 235) !important;
    border: 1px solid rgb(235, 235, 235) !important;
    padding: 1rem !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
}
video {
    border-radius: 10px;
}
iframe {
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}
.MuiCardHeader-content {
    color: #1e7c88;
    font-size: 2rem !important;
}
.logo {
    height: 1.5rem;
    width: 100%;
}
.color-primnary {
    background-color: var(--primary);
    color: var(--primary);
}
.text-primary {
    color: var(--primary) !important;
}
.side-space {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
}
.ql-container {
    resize: vertical !important;
    height: 30vh !important;
    overflow-y: scroll;
}
.scroll {
    overflow-y: scroll !important;
}

.box {
    max-width: 41rem !important;
    overflow-x: initial;
    overflow-y: scroll !important;
}
.hidden {
    height: 0px !important;
    width: 0px !important;
    opacity: 0;
}
.float-end {
    float: inline-end;
}
.img-fit {
    max-height: 100%;
    width: 100%;
    object-fit: cover;
}
.img-contain {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
}
.uploaded-img {
    border-radius: 10px;
    height: 10rem;
    width: 10rem;
    /* max-height: 100%; */
    width: 100%;
    object-fit: cover;
}
.ontent-body img {
    border-radius: 10px;
}
.img-box:hover .trash {
    opacity: 1;
}
.image-cover {
    background-color: rgba(24, 24, 24, 0.13);
    overflow: hidden !important;
    padding: 1rem;
    z-index: 1;
}
.icon-style {
    width: 20px;
}
/* carsul  */
.tag {
    text-align: center;
}
.slider-img-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.slick-next {
    right: 0rem;
    margin-right: 2rem;
}
.slick-prev {
    left: 0rem;
    z-index: 99;
}
.slick-prev:before {
    font-size: 50px !important;
    margin-left: 0rem;
}
.slick-next:before {
    font-size: 50px !important;
}

.img-fit-slide {
    /* margin-top: 3rem; */
    height: 80vh;
    width: 50%;
    overflow-y: scroll !important;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
}
.slide-mid {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.center-center {
    height: 100%;
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.dashed {
    border: 1px dashed var(--primary);
}
audio {
    width: 95% !important;
}
.react-img:hover {
    transform: scale(1.5) !important;
}
.cursor-pointer {
    cursor: pointer !important;
}
.collapse {
    animation-name: example;
    animation-duration: 1s;
}
.img-messenger {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
}
@keyframes example {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.lol {
    height: 2rem !important;
}
.filter-badge {
    padding: 0.6rem;
    border-radius: 2rem;
    background-color: rgba(30, 124, 136, 0.05);
}
.filter-badge-active {
    padding: 0.6rem;
    border-radius: 2rem;
    color: #ffffff;
    background-color: rgba(30, 124, 136, 1);
}
.overflow-x {
    width: 100% !important;
    overflow-x: scroll !important;
}
.overflow-x-full {
    display: flex;
    width: 90vw !important;
    overflow-x: scroll !important;
}
.border-bottom {
    border-bottom: 1px solid rgba(30, 124, 136, 0.322);
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
}
.overflw {
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
}
.overflw-inv {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
}
.over {
    overflow-y: scroll;
    overflow-x: hidden;
}
.over-all {
    overflow-y: scroll;
    overflow-x: scroll;
}
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: #ffffff;
}
::-webkit-scrollbar-thumb {
    background: #1e7c88;
    height: 0px !important;
    padding: 0px;
}
.overflwNew {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
}
.overflw::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    background: #ffffff;
}
.overflw::-webkit-scrollbar-thumb {
    background: #1e7c88;
    height: 0.25rem !important;
    padding: 0.25rem;
}
.overflwNew::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    background: #ffffff;
}
.overflwNew::-webkit-scrollbar-thumb {
    background: #1e7c88;
    height: 0.25rem !important;
    padding: 0.25rem;
}
.over::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    background: #ffffff;
}

.over::-webkit-scrollbar-thumb {
    background: #1e7c88;
    height: 0.25rem !important;
    padding: 0.25rem;
    border-radius: 0.25rem;
}
.over-all::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.5rem;
    background: #ffffff;
}

.over-all::-webkit-scrollbar-thumb {
    background: #1e7c88;
    height: 0.25rem !important;
    padding: 0.25rem;
    border-radius: 0.25rem;
}
.gallery-button {
    background-color: rgba(30, 124, 136, 0.05);
    border: 1px solid rgba(30, 124, 136, 1);
    color: rgba(30, 124, 136, 1);
    border-radius: 10px;
}
.dispimg {
    margin-left: -0.7rem;
    z-index: 200;
}

input[type='checkbox'] {
    display: none;
}

.imgcontainer img {
    transition: transform 0.25s ease;
    cursor: zoom-in;
}

input[type='checkbox']:checked ~ label > img {
    transform: scale(2);
    cursor: zoom-out;
    overflow: scroll !important;
}
.img-fit-slide::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    background: #ffffff;
}
.img-fit-slide::-webkit-scrollbar-thumb {
    background: #1e7c88;
    height: 0.25rem !important;
    padding: 0.25rem;
}
.round {
    height: 12rem;
    width: 12rem;
    border-radius: 6rem;
}

.round-small {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 3rem;
}
.round-dash {
    height: 4rem;
    width: 4rem;
    border-radius: 3rem;
}
.round-esmall {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 3rem;
}
.round-eesmall {
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 3rem;
}
.round-micro {
    height: 0.9rem;
    width: 0.9rem;
    border-radius: 3rem;
    border: 1px solid white;
}
.scroll-x {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
}
.scroll-x::-webkit-scrollbar-thumb {
    background: #1e7c88;
    height: 1rem !important;
    padding: 1rem;
    border-radius: 1rem;
}
.scroll-x::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    background: #ffffff;
}
.leftSwipe {
    transform: translate(-19rem, -2ex);
    transition: transform 500ms ease-in-out 25ms;
}
.rightSwipe {
    transform: translate(0rem, -2ex);
    transition: transform 500ms ease-in-out 25ms;
}
.rotateimg180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.scroll-wrapper {
    overflow: auto;
}

.scroll-wrapper::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    background: #ffffff;
}
.scroll-wrapper::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    height: 0.5rem !important;
    padding: 0.5rem;
}
.d-none {
    display: none !important;
}
.rmdp-container {
    display: none !important;
}
@media only screen and (max-width: 600px) {
    .overflow-x-full {
        display: flex;
    }
    .side-space {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .slick-next {
        right: -1.2rem;
    }
    .slick-prev {
        left: -1.5rem;
    }
    .slick-prev:before {
        font-size: 30px !important;
        margin-left: -0.3rem;
    }
    .slick-next:before {
        font-size: 30px !important;
        margin-right: -0.7rem;
    }
    .round {
        height: 6rem;
        width: 6rem;
        border-radius: 6rem;
    }
}
@media only screen and (max-width: 1240px) {
    .css-18p9tjl {
        padding-left: 0rem;
        padding-right: 0rem;
    }
}
pre {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
}
.fs-14 {
    font-size: 14px !important;
}

/* Firefox scrollbar styles */
/* .scroll,
.box,
.scroll-x,
.ql-container,
.overflw,
.overflw-inv,
.over,
.over-all,
.scroll-wrapper {
    scrollbar-width: thin;
    scrollbar-color: #1e7c88 #ffffff;
} */
.hover-scroll {
    overflow: hidden;
    transition: overflow 0.3s ease-in-out;
}

.hover-scroll:hover {
    overflow: auto;
}

.hover-scroll::-webkit-scrollbar {
    width: 8px;
}

.hover-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}
